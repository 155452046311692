<template>
  <section class="invoice-preview-wrapper">
    <div v-show="show" id="lottie">
      <div class="loading-logo">
        <img style="margin-left: -4rem;"  alt="Logo" src="/logo-transparent.png" />
      </div>
      <div class="loading">
        <div class="effect-1 effects" />
        <div class="effect-2 effects" />
        <div class="effect-3 effects" />
      </div>
    </div>

    <b-col v-if="quote.id" class="notprintable">
      <b-row>
        <b-col cols="7" style="font-weight: 600; font-size: 11.9px; line-height: 12px">
          <div>
            <h2
              v-if="quote.id"
              class="notprintable"
              style="font-weight: 700 !important; font-size: 28px!important; line-height: 34px;!important"
            >
              {{ quote.opportunity.address + ", " + quote.opportunity.geography }}
            </h2>
            <uil-suitcase class="logo icon_possition" size="15px" />
            {{ quote.opportunity.account_name }}
            <span style="padding-left: 20px">
              <uil-tape class="logo icon_possition" size="15px" />
              {{
                quote.opportunity.rentable_sqft.toLocaleString("en", {
                  useGrouping: true,
                }) + " sqft"
              }}
            </span>
            <!-- <span style="padding-left: 20px">
              <uil-bill class="logo icon_possition" size="15px" />
              {{ quote.tfr.tfr }}
            </span>

            <span style="padding-left: 20px; font-weight: 400; color: #b9b9c3">
              {{ "DC Version " + quote.base_spec_version }}
            </span> -->
            <p class="pt-1">
              <!-- pending to be validated -->
              <b-badge
                pill
                variant="primary"
                class="submitted-for-handover"
                v-if="quote.marked_as_active && $route.name == 'Quote Review'"
                >Active</b-badge
              >
              <b-badge
                v-if="!quote.submitted_for_handover == true"
                pill
                :class="badgeClass(quote.tfr.tfr_status)"
              >
                {{ quote.tfr.tfr_status }}
              </b-badge>
              <b-badge v-else pill variant="primary" class="submitted-for-handover"
                >Submitted for Handover</b-badge
              >
              <b-badge
                v-if="
                  quote.submitted_for_handover == true &&
                  quote.tfr.tfr_status == 'Archived'
                "
                pill
                :class="badgeClass(quote.tfr.tfr_status)"
              >
                {{ quote.tfr.tfr_status }}
              </b-badge>
            </p>
          </div>
          <!-- <h2
              v-if="quote.id"
              class="notprintable"
              style="font-weight: 700 !important; font-size: 28px!important; line-height: 34px;!important"
            >
              {{ quote.opportunity.address + ", " + quote.opportunity.geography }}
            </h2>
            <uil-suitcase class="logo" size="15px" />
            {{ quote.opportunity.account_name }}
            <span style="padding-left: 20px">
              <uil-tape class="logo" size="15px" />
              {{ quote.opportunity.rentable_sqft.toLocaleString('en', {useGrouping:true}) + " sqft" }}
            </span>
            <span style="padding-left: 20px">
              <uil-bill class="logo" size="15px" />
              {{ quote.tfr_name }}
            </span>
            <span style="padding-left: 20px; font-weight: 400; color: #b9b9c3">
              {{ "DC Version " + quote.base_spec_version }}
            </span>
            <p class="pt-1">
              <b-badge pill :class="badgeClass(quote.tfr.tfr_status)">
                {{ quote.tfr.tfr_status }}
              </b-badge>
            </p> -->
        </b-col>
        <b-col align-self="end" style="text-align: end; padding-bottom: 20px">
          <b-button
            role="button"
            style="margin-right: 10px"
            target="_blank"
            variant="outline-secondary"
            @click="downloadCadFile"
            v-if="false"
          >
            Download CAD Data
            <feather-icon icon="DownloadIcon" />
          </b-button>
          <!-- <b-button
              :href="`https://clearspace.lightning.force.com/lightning/r/Test_Fit_Request__c/${quote.tfr.sf_tfr_id}/view`"
              role="button"
              style="margin-right: 10px"
              target="_blank"
              variant="outline-secondary"
              :disabled="!quote.tfr.sf_tfr_id"
            >
              Open TFR
              <feather-icon icon="ExternalLinkIcon" />
            </b-button> -->

          <b-button
            style="margin-right: 10px"
            variant="outline-secondary"
            @click="exitEditMode"
          >
            Exit Edit Mode
            <!-- <feather-icon icon="ExternalLinkIcon" /> -->
          </b-button>
          <b-button
            variant="primary"
            :disabled="quote.tfr.tfr_status == 'Archived'"
            @click="updateQuote"
          >
            Save Quote
          </b-button>
        </b-col>
      </b-row>
    </b-col>
    <b-row v-if="quote.id" class="invoice-preview edit-page">
      <!-- Left Col: Card -->
      <!-- <b-col cols="12" md="4" xl="3" class="invoice-actions">
            <comment-section :comments='comments' :quoteId='quoteId' />
        </b-col> -->
      <b-col cols="12" md="12" xl="12">
        <b-tabs
          class="mt-2"
          v-model="tabIndex"
          pills
          vertical
          nav-wrapper-class="col-3"
          content-class="col-9 "
        >
          <template v-if="!isBusy">
            <div
              v-if="
                quote.tfr.tfr_status == 'Quote Completed' ||
                quote.opportunity.ccdc_signed == true
              "
              class="archived-warning"
            >
              <span
                >Estimate is complete or marked as contract signed and can no longer be
                edited.</span
              >
            </div>
          </template>
          <b-tab active>
            <template #title>
              <b-col align-self="start" cols="11">
                <uil-bill class="logo" size="15px" />
                Itemized Estimate
              </b-col>
              <b-col
                align-self="end"
                class="d-flex align-items-center justify-content-end"
                cols="1"
              />
            </template>
            <b-card class="invoice-preview-card">
              <b-card-body>
                <!-- Header -->
                <b-card-body class="invoice-padding pb-0">
                  <div
                    class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
                  >
                    <!-- Header: Left Content -->
                    <div>
                      <div class="logo-wrapper">
                        <logo />
                      </div>
                      <p class="card-text mb-25">Clearspace Offices Inc.</p>
                      <p class="card-text mb-25 base-paragraph">
                        901-20 Victoria Street
                        <!-- {{
                          quote.geography != "Montréal"
                            ? "901-20 Victoria Street"
                            : montrealAddress
                        }} -->
                      </p>
                      <p class="card-text mb-0 base-paragraph">
                        Toronto, ON, Canada M5C 2N8
                        <!-- {{
                          quote.geography != "Montréal"
                            ? "Toronto, ON, Canada M5C 2N8"
                            : "Montréal, QC, Canada H3B 2N2"
                        }} -->
                      </p>
                    </div>

                    <!-- Header: Right Content -->
                    <div class="mt-md-0 mt-2">
                      <h4 class="invoice-numbercs mb-2" style="text-align: end">
                        Summary #{{ quote.tfr.tfr }}
                      </h4>
                      <div class="invoice-date-wrapper">
                        <p class="invoice-date-title header-label">Issue Date:</p>
                        <p class="invoice-date">
                          <b-input-group class="mb-1">
                            <b-form-input
                              id="example-input"
                              v-model="quote.issue_date"
                              autocomplete="off"
                              placeholder="YYYY-MM-DD"
                              show-decade-nav
                              type="text"
                              :disabled="
                                quote.tfr.tfr_status == 'Quote Completed' ||
                                quote.opportunity.ccdc_signed == true
                              "
                            />
                            <b-input-group-append>
                              <b-form-datepicker
                                v-model="quote.issue_date"
                                aria-controls="example-input"
                                button-only
                                button-variant="outline-secondary"
                                locale="en-US"
                                right
                                show-decade-nav
                                size="sm"
                                :disabled="
                                  quote.tfr.tfr_status == 'Quote Completed' ||
                                  quote.opportunity.ccdc_signed == true
                                "
                              />
                            </b-input-group-append>
                          </b-input-group>
                        </p>
                      </div>
                      <div class="invoice-date-wrapper">
                        <p class="invoice-date-title header-label">Expiry Date:</p>
                        <div class="w-100 text-right">
                          <p class="invoice-date">
                            {{
                              new Date(quote.expiration_date).toLocaleDateString("en-CA")
                            }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-card-body>

                <!-- Spacer
        <hr class="invoice-spacing">
        -->

                <!-- Invoice Client & Payment Details -->
                <b-card-body class="invoice-padding pt-0">
                  <b-row class="invoice-spacing">
                    <!-- Col: Invoice To -->
                    <b-col class="p-0" cols="9">
                      <h6 class="header-label">Address</h6>
                      <h2
                        class="card-text mb-25"
                        style="
                          font-weight: 700 !important;
                          font-size: 21px !important;
                          line-height: 26px !important;
                          color: #636363 !important;
                        "
                      >
                        {{ quote.address }}
                      </h2>
                      <h2
                        class="card-text mb-25"
                        style="
                          font-weight: 700 !important;
                          font-size: 21px !important;
                          line-height: 26px !important;
                          color: #636363 !important;
                        "
                      >
                        {{ quote.geography }}
                        <!-- {{quote.project.city + ', ' + quote.project.state + ', Canada ' + quote.project.postcode}} -->
                      </h2>
                      <h6 class="mt-2 header-label">Size</h6>
                      <p class="card-text mb-2">
                        {{
                          Number(quote.sqft).toLocaleString("en", {
                            minimumFractionDigits: 0,
                          }) + " sqft"
                        }}
                      </p>
                    </b-col>
                  </b-row>
                </b-card-body>
                <hr />
                <b-card-title>
                  <b-row class="mx-2">
                    <b-col cols="8">
                      <h2><b>Itemized Estimate</b></h2>
                    </b-col>
                    <b-col
                      align-self="end"
                      class="d-flex justify-content-end"
                      cols="4"
                      style="text-align: end"
                    >
                      <b-col md="6" align-self="center" align-h="end">
                        <label
                          style="
                            font-weight: 600 !important;
                            font-size: 12px;
                            line-height: 23px;
                            color: #b9b9c3;
                            letter-spacing: 0.6px;
                            text-transform: uppercase;
                          "
                          >Total Price (w/ D&I)</label
                        >
                        <h2
                          style="
                            color: #6e6b7b;
                            font-weight: 500 !important;
                            font-size: 24px;
                            line-height: 24px;
                          "
                        >
                          {{
                            Number(totalPrice).toLocaleString("en-CA", {
                              minimumFractionDigits: 2,
                              style: "currency",
                              currency: "CAD",
                            })
                          }}
                        </h2>
                      </b-col>
                      <b-col md="6" align-self="center" align-h="end">
                        <label
                          style="
                            font-weight: 600 !important;
                            font-size: 12px;
                            line-height: 23px;
                            color: #b9b9c3;
                            letter-spacing: 0.6px;
                            text-transform: uppercase;
                          "
                          >Total COGS</label
                        >
                        <h2
                          style="
                            color: #6e6b7b;
                            font-weight: 500 !important;
                            font-size: 24px;
                            line-height: 24px;
                          "
                        >
                          {{
                            Number(totalCost).toLocaleString("en-CA", {
                              minimumFractionDigits: 2,
                              style: "currency",
                              currency: "CAD",
                            })
                          }}
                        </h2>
                      </b-col>
                    </b-col>
                  </b-row>
                </b-card-title>
                <b-row>
                  <b-col cols="6">
<!--                    <b-button @click="collapsed_other_budget=!collapsed_other_budget" class="mr-1"> {{ collapsed_other_budget?'Expand':'Collapse' }} Table</b-button>-->
                  </b-col>
                  <b-col
                      align-self="end"
                      cols="6"
                      style="text-align: end; padding-bottom: 20px"
                  >
                    <b-button class="mr-1" variant="primary" @click="additionSetModal=true" :disabled="
                        quote.tfr.tfr_status == 'Quote Completed' ||
                          quote.opportunity.ccdc_signed == true || quote.tfr.tfr_status=='Archived'
                      ">
                      Add addition sets
                    </b-button>
                    <b-button
                        v-b-modal.create-itemized-quote-modal
                        variant="primary"
                        :disabled="
                        quote.tfr.tfr_status == 'Quote Completed' ||
                        quote.opportunity.ccdc_signed == true ||
                        quote.tfr.tfr_status == 'Archived'
                      "
                        class="mr-4"
                    >
                      Add Items
                    </b-button>
                  </b-col>
                </b-row>
                <b-row class="mx-2">
                    <template v-for="group,index in groups" v-if="group.items && group.items.length>0">
                      <b-col cols="6">
                        <h4 class="mt-2">{{group.label}}</h4>
                      </b-col>
                      <b-col cols="6" class="d-flex justify-content-end">
                        <b-button @click="group.is_collapsed=!group.is_collapsed" class="my-1 mr-1"> {{ group.is_collapsed?'Expand':'Collapse' }} Table</b-button>
                      </b-col>
                      <b-col cols="12">
                        <div class="mr-1">
                          <b-table-simple :key="group.key"
                                          responsive
                                          :busy="isBusy"
                                          :sticky-header="true"
                                          :no-border-collapse="true"
                                          :style="group.is_collapsed?'height: 27.5vh':'height: 67vh'"
                                          style="border-bottom: 1px solid #d3d3d3 !important;"
                          >
                            <b-thead>
                              <b-th style="width: 25%" class="sticky"> Item </b-th>
                              <b-th
                                  v-for="(fO, index) in itemFields.filter((i)=>i.key!='budget_group')"
                                  :key="'fo' + index"
                                  v-if="fO.key != 'actions'"
                                  class="sticky"
                              >
                                <div style="white-space: nowrap">
                                  {{ fO.label }}
                                </div>
                              </b-th>
                              <b-th :class="visibleActions()" class="sticky text-center">
                                <span :class="visibleActions()">ACTIONS</span>
                              </b-th>
                            </b-thead>

                            <!-- Draggable rows -->
                            <draggable
                                v-model="group.items"
                                group="quoteItems"
                                tag="tbody"
                                @change="onChange(index)"
                            >
                              <b-tr v-for="item in group.items" :key="item.id" class="item-row">
                                <b-td>
                                  <div style="max-width: 400px">
                                    {{ item.name }} <br />
                                    <span
                                        v-if="item.details"
                                        style="
                                  font-size: 12px;
                                  font-style: normal;
                                  font-weight: 400;
                                  line-height: 18px;
                                  letter-spacing: 0px;
                                  text-align: left;
                                  color: #b9b9c3;
                                  white-space: pre-line;
                                "
                                    >
                                {{ item.details }}</span
                                    >
                                  </div></b-td
                                >
                                <b-td>{{ item.internal_note }}</b-td>

                                <!--                         <b-td>
                                                           <b-badge
                                                               pill
                                                               variant="primary"
                                                               :class="item.budget_group.replace(/[^A-Z0-9]+/gi, '')"
                                                               style="font-weight: 600; font-size: 13px; line-height: 13px"
                                                           >
                                                             <div v-if="item.budget_group == 'Construction'">
                                                               <uil-constructor /> CSTRN
                                                             </div>
                                                             <div v-else-if="item.budget_group == 'FF&E'">
                                                               <uil-chair /> FF&E
                                                             </div>
                                                             <div v-else-if="item.budget_group == 'AV/IT'">
                                                               <uil-wifi /> AVIT
                                                             </div>
                                                             <div v-else><uil-user /> SOFT</div>
                                                           </b-badge></b-td
                                                         >-->
                                <b-td>
                                  <div v-if="item.uniformat != null">
                                    {{
                                      searchUniformatCodeName(
                                          item.budget_group == "AV/IT"
                                              ? "AVIT"
                                              : item.budget_group,
                                          item.uniformat
                                      )
                                    }}
                                  </div>
                                </b-td>
                                <b-td>
                                  <div
                                      :class="[item.total_price < 0 ? 'credit' : '']"
                                      style="text-align: center"
                                  >
                                    {{
                                      Number(item.total_price).toLocaleString("en-CA", {
                                        minimumFractionDigits: 2,
                                        style: "currency",
                                        currency: "CAD",
                                      })
                                    }}
                                  </div>
                                </b-td>
                                <!-- <b-td>
                                  <div class="cost-line" style="text-align: center">
                                    <uil-arrow-growth
                                      style="color: #ff9f43"
                                      v-if="item.is_adjustment == true"
                                    />
                                    {{
                                      Number(item.cost_per_sqft).toLocaleString("en-CA", {
                                        minimumFractionDigits: 2,
                                        style: "currency",
                                        currency: "CAD",
                                      })
                                    }}
                                  </div>
                                </b-td> -->
                                <b-td>
                                  <div class="cost-line" style="text-align: center">
                                    <uil-arrow-growth
                                        style="color: #ff9f43"
                                        v-if="item.is_adjustment == true"
                                    />
                                    {{
                                      Number(item.total_cost).toLocaleString("en-CA", {
                                        minimumFractionDigits: 2,
                                        style: "currency",
                                        currency: "CAD",
                                      })
                                    }}
                                  </div>
                                </b-td>
                                <b-td :class="visibleActions()">
                                  <div class="text-nowrap">
                                    <a
                                        v-b-modal.edit-itemized-quote
                                        @click="editOption(item)"
                                        :disabled="
                                  quote.status == 'Archived' || quote.status == 'Approved'
                                "
                                    >
                                      <feather-icon
                                          id="tooltip-eye"
                                          class="mx-1"
                                          icon="Edit3Icon"
                                          size="16"
                                          style="margin-right: 8px !important"
                                      />
                                      <span class="align-middle" style="margin-right: 15px"
                                      >Edit</span
                                      >
                                    </a>
                                    <b-tooltip target="tooltip-eye" title="Edit Option" />

                                    <!-- Dropdown -->
                                    <b-dropdown no-caret toggle-class="p-0" variant="link">
                                      <template #button-content>
                                        <feather-icon
                                            class="align-middle text-body"
                                            icon="MoreVerticalIcon"
                                            size="16"
                                        />
                                      </template>
                                      <b-dropdown-item
                                          target="_blank"
                                          @click="deleteOption(item)"
                                          :disabled="
                                    quote.status == 'Archived' ||
                                    quote.status == 'Approved'
                                  "
                                      >
                                        <feather-icon icon="Trash2Icon" />
                                        <span class="align-middle ml-50">Delete</span>
                                      </b-dropdown-item>
                                    </b-dropdown>
                                  </div>
                                </b-td>
                              </b-tr>
                            </draggable>
                          </b-table-simple>
                        </div>
                      </b-col>
                    </template>
                </b-row>
<!--                <b-row>
                  <b-col cols="12">
                    <b-table-simple
                      class="table"
                      responsive
                      :busy="isBusy"
                      :sticky-header="true"
                      :no-border-collapse="true"
                      :style="collapsed_other_budget?'height: 27.5vh':'height: 67vh'"
                    >
                      <b-thead>
                        <b-th style="width: 25%" class="sticky"> Item </b-th>
                        <b-th
                          v-for="(fO, index) in itemFields"
                          :key="'fo' + index"
                          v-if="fO.key != 'actions'"
                          class="sticky"
                        >
                          <div style="white-space: nowrap">
                            {{ fO.label }}
                          </div>
                        </b-th>
                        <b-th :class="visibleActions()" class="sticky text-center">
                          <span :class="visibleActions()">ACTIONS</span>
                        </b-th>
                      </b-thead>

                      &lt;!&ndash; Draggable rows &ndash;&gt;
                      <draggable
                        v-model="quoteItems"
                        group="quoteItes"
                        tag="tbody"
                        @change="onChange('option')"
                      >
                        <b-tr v-for="item in quoteItems" :key="item.id" class="item-row">
                          <b-td>
                            <div style="max-width: 400px">
                              {{ item.name }} <br />
                              <span
                                v-if="item.details"
                                style="
                                  font-size: 12px;
                                  font-style: normal;
                                  font-weight: 400;
                                  line-height: 18px;
                                  letter-spacing: 0px;
                                  text-align: left;
                                  color: #b9b9c3;
                                  white-space: pre-line;
                                "
                              >
                                {{ item.details }}</span
                              >
                            </div></b-td
                          >
                          <b-td>{{ item.internal_note }}</b-td>

                          <b-td>
                            <b-badge
                              pill
                              variant="primary"
                              :class="item.budget_group.replace(/[^A-Z0-9]+/gi, '')"
                              style="font-weight: 600; font-size: 13px; line-height: 13px"
                            >
                              <div v-if="item.budget_group == 'Construction'">
                                <uil-constructor /> CSTRN
                              </div>
                              <div v-else-if="item.budget_group == 'FF&E'">
                                <uil-chair /> FF&E
                              </div>
                              <div v-else-if="item.budget_group == 'AV/IT'">
                                <uil-wifi /> AVIT
                              </div>
                              <div v-else><uil-user /> SOFT</div>
                            </b-badge></b-td
                          >
                          <b-td>
                            <div v-if="item.uniformat != null">
                              {{
                                searchUniformatCodeName(
                                  item.budget_group == "AV/IT"
                                    ? "AVIT"
                                    : item.budget_group,
                                  item.uniformat
                                )
                              }}
                            </div>
                          </b-td>
                          <b-td>
                            <div
                              :class="[item.total_price < 0 ? 'credit' : '']"
                              style="text-align: center"
                            >
                              {{
                                Number(item.total_price).toLocaleString("en-CA", {
                                  minimumFractionDigits: 2,
                                  style: "currency",
                                  currency: "CAD",
                                })
                              }}
                            </div>
                          </b-td>
                          &lt;!&ndash; <b-td>
                            <div class="cost-line" style="text-align: center">
                              <uil-arrow-growth
                                style="color: #ff9f43"
                                v-if="item.is_adjustment == true"
                              />
                              {{
                                Number(item.cost_per_sqft).toLocaleString("en-CA", {
                                  minimumFractionDigits: 2,
                                  style: "currency",
                                  currency: "CAD",
                                })
                              }}
                            </div>
                          </b-td> &ndash;&gt;
                          <b-td>
                            <div class="cost-line" style="text-align: center">
                              <uil-arrow-growth
                                style="color: #ff9f43"
                                v-if="item.is_adjustment == true"
                              />
                              {{
                                Number(item.total_cost).toLocaleString("en-CA", {
                                  minimumFractionDigits: 2,
                                  style: "currency",
                                  currency: "CAD",
                                })
                              }}
                            </div>
                          </b-td>
                          <b-td :class="visibleActions()">
                            <div class="text-nowrap">
                              <a
                                v-b-modal.edit-itemized-quote
                                @click="editOption(item)"
                                :disabled="
                                  quote.status == 'Archived' || quote.status == 'Approved'
                                "
                              >
                                <feather-icon
                                  id="tooltip-eye"
                                  class="mx-1"
                                  icon="Edit3Icon"
                                  size="16"
                                  style="margin-right: 8px !important"
                                />
                                <span class="align-middle" style="margin-right: 15px"
                                  >Edit</span
                                >
                              </a>
                              <b-tooltip target="tooltip-eye" title="Edit Option" />

                              &lt;!&ndash; Dropdown &ndash;&gt;
                              <b-dropdown no-caret toggle-class="p-0" variant="link">
                                <template #button-content>
                                  <feather-icon
                                    class="align-middle text-body"
                                    icon="MoreVerticalIcon"
                                    size="16"
                                  />
                                </template>
                                <b-dropdown-item
                                  target="_blank"
                                  @click="deleteOption(item)"
                                  :disabled="
                                    quote.status == 'Archived' ||
                                    quote.status == 'Approved'
                                  "
                                >
                                  <feather-icon icon="Trash2Icon" />
                                  <span class="align-middle ml-50">Delete</span>
                                </b-dropdown-item>
                              </b-dropdown>
                            </div>
                          </b-td>
                        </b-tr>
                      </draggable>
                    </b-table-simple>
                    <b-col cols="12" class=" my-1">
                      <b-button @click="collapsed_sc_budget=!collapsed_sc_budget" class="mr-1"> {{ collapsed_sc_budget?'Expand':'Collapse' }} Table</b-button>
                    </b-col>
                    <b-table-simple
                      class="table-item mt-4"
                      responsive
                      :busy="isBusy"
                      :sticky-header="true"
                      :no-border-collapse="true"
                      style="height: 27.5vh"
                    >
                      <b-thead>
                        <b-th style="width: 25%" class="sticky"> soft cost </b-th>
                        <b-th
                          v-for="(fO, index) in scItemsFields"
                          :key="'fo' + index"
                          v-if="fO.key != 'actions'"
                          class="sticky"
                        >
                          <div style="white-space: nowrap">
                            {{ fO.label }}
                          </div>
                        </b-th>
                        <b-th :class="visibleActions()" class="sticky text-center">
                          <span :class="visibleActions()">ACTIONS</span>
                        </b-th>
                      </b-thead>

                      &lt;!&ndash; Draggable rows &ndash;&gt;
                      <draggable
                        v-model="scItems"
                        group="scItems"
                        tag="tbody"
                        @change="onChange('option')"
                      >
                        <b-tr v-for="item in scItems" :key="item.id" class="item-row">
                          <b-td>
                            <div style="max-width: 400px">
                              {{ item.name }} <br />
                              <span
                                v-if="item.details"
                                style="
                                  font-size: 12px;
                                  font-style: normal;
                                  font-weight: 400;
                                  line-height: 18px;
                                  letter-spacing: 0px;
                                  text-align: left;
                                  color: #b9b9c3;
                                  white-space: pre-line;
                                "
                              >
                                {{ item.details }}</span
                              >
                            </div></b-td
                          >
                          <b-td>{{ item.internal_note }}</b-td>

                          <b-td>
                            <b-badge
                              pill
                              variant="primary"
                              :class="item.budget_group.replace(/[^A-Z0-9]+/gi, '')"
                              style="font-weight: 600; font-size: 13px; line-height: 13px"
                            >
                              <div v-if="item.budget_group == 'Construction'">
                                <uil-constructor /> CSTRN
                              </div>
                              <div v-else-if="item.budget_group == 'FF&E'">
                                <uil-chair /> FF&E
                              </div>
                              <div v-else-if="item.budget_group == 'AV/IT'">
                                <uil-wifi /> AVIT
                              </div>
                              <div v-else><uil-user /> SOFT</div>
                            </b-badge></b-td
                          >
                          <b-td>
                            <div v-if="item.uniformat != null">
                              {{
                                searchUniformatCodeName(
                                  item.budget_group == "AV/IT"
                                    ? "AVIT"
                                    : item.budget_group,
                                  item.uniformat
                                )
                              }}
                            </div>
                          </b-td>
                          <b-td>
                            <div
                              :class="[item.total_price < 0 ? 'credit' : '']"
                              style="text-align: center"
                            >
                              {{
                                Number(item.total_price).toLocaleString("en-CA", {
                                  minimumFractionDigits: 2,
                                  style: "currency",
                                  currency: "CAD",
                                })
                              }}
                            </div>
                          </b-td>
                          &lt;!&ndash; <b-td>
                            <div class="cost-line" style="text-align: center">
                              <uil-arrow-growth
                                style="color: #ff9f43"
                                v-if="item.is_adjustment == true"
                              />
                              {{
                                Number(item.cost_per_sqft).toLocaleString("en-CA", {
                                  minimumFractionDigits: 2,
                                  style: "currency",
                                  currency: "CAD",
                                })
                              }}
                            </div>
                          </b-td> &ndash;&gt;
                          <b-td>
                            <div class="cost-line" style="text-align: center">
                              <uil-arrow-growth
                                style="color: #ff9f43"
                                v-if="item.is_adjustment == true"
                              />
                              {{
                                Number(item.total_cost).toLocaleString("en-CA", {
                                  minimumFractionDigits: 2,
                                  style: "currency",
                                  currency: "CAD",
                                })
                              }}
                            </div>
                          </b-td>
                          <b-td :class="visibleActions()">
                            <div class="text-nowrap">
                              <a
                                v-b-modal.edit-itemized-quote
                                @click="editOption(item)"
                                :disabled="
                                  quote.status == 'Archived' || quote.status == 'Approved'
                                "
                              >
                                <feather-icon
                                  id="tooltip-eye"
                                  class="mx-1"
                                  icon="Edit3Icon"
                                  size="16"
                                  style="margin-right: 8px !important"
                                />
                                <span class="align-middle" style="margin-right: 15px"
                                  >Edit</span
                                >
                              </a>
                              <b-tooltip target="tooltip-eye" title="Edit Option" />

                              &lt;!&ndash; Dropdown &ndash;&gt;
                              <b-dropdown no-caret toggle-class="p-0" variant="link">
                                <template #button-content>
                                  <feather-icon
                                    class="align-middle text-body"
                                    icon="MoreVerticalIcon"
                                    size="16"
                                  />
                                </template>
                                <b-dropdown-item
                                  target="_blank"
                                  @click="deleteOption(item)"
                                  :disabled="
                                    quote.status == 'Archived' ||
                                    quote.status == 'Approved'
                                  "
                                >
                                  <feather-icon icon="Trash2Icon" />
                                  <span class="align-middle ml-50">Delete</span>
                                </b-dropdown-item>
                              </b-dropdown>
                            </div>
                          </b-td>
                        </b-tr>
                      </draggable>
                    </b-table-simple>
                  </b-col>
                </b-row>-->
              </b-card-body>
            </b-card>
          </b-tab>
          <template #tabs-end>
            <comment-section
              ref="commentComponent"
              :comments="comments"
              :demolition-image="quote.demolition_image"
              :quote-id="quoteId"
              style="max-width: none"
              @commentChanged="commentChanged"
              class="mt-4"
            />
          </template>
        </b-tabs>
      </b-col>
    </b-row>
    <new-item
      :quote-id="quoteId"
      :sqft="quote.sqft"
      @updatedItems="childOptions"
      :constructionItems="quoteConstructionItems"
      :base_spec_version="base_spec_version_c"
    />
    <edit-item
      :option-to-edit="optionToEdit"
      :quote-id="quoteId"
      :sqft="quote.sqft"
      @updatedItems="childOptions"
      @deleteItem="deleteOption"
      :constructionItems="quoteConstructionItems"
      :base_spec_version="base_spec_version_c"
    />
    <b-modal v-model="additionSetModal" title="Add Addition set" no-close-on-backdrop centered size="lg" @close="closeAdditionSetModal">
      <b-alert show variant="warning" style="height: 65px"><div class="mx-2">
        Please note all Additions from the selected Set will be added regardless of existing Additions. This could result in duplicate entries. Please confirm the result after applying.
      </div></b-alert>
      <b-row>
        <b-col :cols="addition_set_selected === 0 || addition_set_selected===null ? 12 : 10" class="my-0">
          <validation-provider
              #default="{ errors }"
              name="Addition Set"
              rules="required"
          >
            <div>
              <label>
                Select Addition Set
              </label>
              <v-select
                  style="z-index: 10000"
                  label="name"
                  :options="addition_sets"
                  item-text="name"
                  :reduce="(option) => option.id"
                  :clearable="false"
                  v-model="addition_set_selected"
                  max-height="100px"
                  class="style-chooser-addition"
                  placeholder="Select Addition Set"
              />
            </div>
            <small class="text-danger" v-if="errors[0]"
            >{{ errors[0] }}<br
            /></small>
          </validation-provider>
        </b-col>
        <b-col  class="p-0 m-0" v-if="addition_set_selected != 0 && addition_set_selected!=null" :cols="addition_set_selected == 0 ? 0 : 2">
          <b-button style="margin-top:25px" class="ml-1" @click="gotoAdditionSet()">
            Review</b-button
          >
        </b-col>
      </b-row>
      <template #modal-footer>
        <div
            class="float-right">
          <b-button @click="closeAdditionSetModal()" class="ml-1">
            Cancel
          </b-button>
          &nbsp;
          <b-button
              variant="primary"
              :disabled="addition_set_selected !== 0 && addition_set_selected===null||is_adding_addition"
              @click="addAdditionSet()"
          >
            Add additions
          </b-button>
        </div>
      </template>
    </b-modal>
    </b-modal>
  </section>
</template>

<script>
import {
  UilBill,
  UilBox,
  UilCheckCircle,
  UilEditAlt,
  UilExclamationTriangle,
  UilHardHat,
  UilPlus,
  UilRuler,
  UilSuitcase,
  UilTape,
  UilWifi,
  UilSetting,
  UilConstructor,
  UilArrowGrowth,
  UilHistory,
  UilTrashAlt,
  UilMoneyBill,
  UilUser,
} from "@iconscout/vue-unicons";
import {
  BButton,
  BCard,
  BCardBody,
  BCardText,
  BCardTitle,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BFormDatepicker,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
  BRow,
  BTab,
  BTable,
  BTableSimple,
  BTabs,
  BTbody,
  BTd,
  BTfoot,
  BTh,
  BThead,
  BTooltip,
  BTr,
  VBToggle,
  BBadge,
  BSpinner, BModal, BAlert,
} from "bootstrap-vue";
import UilChair from "@/assets/CustomIcons/uil-chair";
import JsonExcel from "vue-json-excel";
import Logo from "@core/layouts/components/Logo.vue";
import Ripple from "vue-ripple-directive";
import CommentSection from "../../../Comments/Components/Comment.vue";
import NewItem from "./Modals/NewItem.vue";
import EditItem from "./Modals/EditItem.vue";
import draggable from "vuedraggable";
import uniformat from "@/assets/objects/uniformat-codes.json";
import vSelect from "vue-select";
import {ValidationProvider} from "vee-validate";
export default {
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  components: {
    BAlert, ValidationProvider, BModal, vSelect,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardText,
    BFormCheckbox,
    BTableSimple,
    BButton,
    BTable,
    BTooltip,
    BDropdown,
    BDropdownItem,
    BTr,
    BTh,
    BThead,
    BTfoot,
    BTd,
    BTbody,
    Logo,
    CommentSection,
    UilRuler,
    UilSuitcase,
    UilBill,
    UilTape,
    UilBox,
    UilWifi,
    UilHardHat,
    UilPlus,
    UilCheckCircle,
    UilEditAlt,
    UilExclamationTriangle,
    UilMoneyBill,
    BFormDatepicker,
    BInputGroupAppend,
    BFormInput,
    BInputGroup,
    BTabs,
    BTab,
    BPagination,
    BCardTitle,
    JsonExcel,
    UilSetting,
    BBadge,
    UilChair,
    UilConstructor,
    UilArrowGrowth,
    UilHistory,
    UilTrashAlt,
    UilChair,
    UilUser,
    BSpinner,
    NewItem,
    EditItem,
    draggable,
  },
  data() {
    return {
      //additions sets modal variable
      addition_sets:[],
      additionSetModal: false,
      addition_set_selected: null,
      is_adding_addition: false,
      //variable to send update at backend
      additions: [],
      deleted_additions:[],
      totalPrice: 0,
      totalCost: 0,
      quoteId: this.$route.params.quoteid,
      quote: {
        opportunity: {},
        comments: {},
        construction_cost_per_sqft: 0,
        construction_buffer: 0,
      },
      optionToEdit: [],
      tabIndex: 0,
      isBusy: true,
      comments: {},
      show: true,
      newComment: "",
      quoteItems: [],
      scItems: [],
      options: [],
      quoteConstructionItems: [],
      itemFields: [
        // {
        //   key: "name",
        //   label: "ITEM",
        //   sortable: true,
        //   thStyle: { width: "15%" },
        // },
        {
          key: "internal_note",
          label: "INTERNAL NOTE",
          sortable: false,
        },
        {
          key: "budget_group",
          label: "BUDGET",
          sortable: false,
        },
        {
          key: "uniformat_code",
          label: "tender Code",
          sortable: false,
        },
        // {
        //   key: "quantity",
        //   label: "Quantity",
        //   sortable: true,
        // },
        // {
        //   key: "unit_price",
        //   label: "UNIT PRICE",
        //   sortable: false,
        // },
        {
          key: "total_price",
          label: "Total Price (w/ D&I)",
          sortable: true,
        },
        // {
        //   key: "unit_cost",
        //   label: "COGS / Unit",
        //   sortable: true,
        // },
        {
          key: "total_cost",
          label: "Total COGS",
          sortable: true,
        },
        {
          key: "actions",
          label: "ACTIONS",
          tdClass: this.visibleActions,
        },
      ],
      scItemsFields: [
        // {
        //   key: "name",
        //   label: "SOFT COST",
        //   sortable: true,
        //   thStyle: { width: "15%" },
        // },
        {
          key: "internal_note",
          label: "INTERNAL NOTE",
          sortable: false,
        },
        {
          key: "budget_group",
          label: "BUDGET",
          sortable: false,
        },
        {
          key: "uniformat_code",
          label: "tender Code",
          sortable: false,
        },
        // {
        //   key: "quantity",
        //   label: "Quantity",
        //   sortable: true,
        // },
        // {
        //   key: "unit_price",
        //   label: "UNIT PRICE",
        //   sortable: false,
        // },
        {
          key: "total_price",
          label: "Total Price",
          sortable: true,
        },
        // {
        //   key: "unit_cost",
        //   label: "COGS / Unit",
        //   sortable: true,
        // },
        {
          key: "total_cost",
          label: "Total COGS",
          sortable: true,
        },
        {
          key: "actions",
          label: "ACTIONS",
          tdClass: this.visibleActions,
        },
      ],
      // * uniformat codes object
      uniformat_codes: uniformat,
      // vars for update table heighs
      collapsed_other_budget: true,
      collapsed_sc_budget: true,
      groups: [
        {
          label: "Hard Construction",
          key: "Construction",
          is_collapsed:true,
          items:[],
        },
        {
          label: "Furniture",
          key: "FF&E",
          is_collapsed:true,
          items:[]
        },
        {
          label: "AV/IT",
          key: "AV/IT",
          is_collapsed:true,
          items:[]
        },
        {
          label: "Soft Costs",
          key: "Soft Cost",
          is_collapsed:true,
          items:[]
        },
      ]
    };
  },
  computed: {
    hasComment() {
      if (this.childComment != "") {
        return true;
      } else {
        return false;
      }
    },

    discardModalMessage() {
      if (this.hasComment && this.quoteHasChanged) {
        return "It looks like you have unsaved changes and comments on this page.";
      } else if (this.hasComment) {
        return "It looks like you have unsaved comments on this page.";
      } else {
        return "It looks like you have unsaved changes on this page.";
      }
    },
    montrealAddress() {
      if (this.quote.language == "fr") {
        return "1325-1010 rue de la Gauchetière Ouest";
      } else {
        return "1325-1010 de la Gauchetière West Street";
      }
    },
    quoteHasChanged() {
      return false;
    },
    base_spec_version_c() {
      return this.quote.base_spec_version;
    },
    // totalPrice() {
    //   var sum = 0;
    //   this.options.forEach((e) => {
    //     sum += parseFloat(e.total_price);
    //   });
    //   return parseFloat(sum);
    // },
    // totalCost() {
    //   var sum = 0;
    //   this.options.forEach((e) => {
    //     sum += parseFloat(e.total_cost);
    //   });
    //   return parseFloat(sum);
    // },
  },
  beforeMount() {
    this.getQuote();
    this.getAdditionSets()
  },
  methods: {
    downloadCadFile() {
      this.$http({
        url: `/quote/${this.quoteId}/cad-data`,
        method: "GET",
        responseType: "blob",
      }).then((res) => {
        var fileURL = window.URL.createObjectURL(new Blob([res.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", this.quote.csv_filename);
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
    scrollToTop() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
    getAdditionSets() {
      this.$http
          .get('/additions/sets')
          .then(response => {
            const { data } = response
            this.addition_sets = data
            // this.addition_set_selected = this.addition_sets[0].id
          })
          .catch(error => {
            this.show = false
            console.log(error)
            console.log(error)
            this.showToast('danger', error.response.data.message)
          })
    },
    getQuote() {
      this.$http
        .get(`/quote/${this.quoteId}`)
        .then((response) => {
          this.quote = response.data;
          this.comments = this.quote.comments;
          this.quoteConstructionItems = this.quote.construction_spec_quote.filter(
            (x) => x.name !== "Construction Management"
          );
          this.options = this.quote.options;
          this.quoteItems = this.quote.options.filter(
            (x) => x.budget_group != "Soft Cost"
          );
          this.scItems = this.quote.options.filter((x) => x.budget_group == "Soft Cost");
          this.opportunity = this.quote.opportunity;
          if (this.quote.tfr.tfr) {
            document.title = `[${this.quote.tfr.tfr}] Edit Quote`;
          }
          this.updateBreadcrumb();
          //calc total prices and cogs
          this.options.forEach((e) => {
            this.totalPrice += parseFloat(e.total_price);
          });
          this.options.forEach((e) => {
            this.totalCost += parseFloat(e.total_cost);
          });
          this.groups.forEach((g)=>{
            g.items = this.quote.options.filter((o)=>o.budget_group===g.key)
          })
        })
        .catch((error) => {
          // this.showToast('danger', error.response.data.message ?? '')
        });
      this.isBusy = false;
    },
    updateBreadcrumb() {
      this.$route.meta.breadcrumb[0].text = `${this.quote.opportunity.address}, ${this.quote.opportunity.geography}`;
      this.$route.meta.breadcrumb[0].to = `/opportunities/${this.$route.params.opportunityid}`;
      this.$route.meta.breadcrumb[0].active = false;
      this.$route.meta.breadcrumb[2].text = `Quote #${this.quote.tfr.tfr}`;
      this.$route.meta.breadcrumb[2].to = `/opportunities/${this.$route.params.opportunityid}/${this.$route.params.quoteid}`;
      this.$route.meta.breadcrumb[2].active = false;
      this.$root.$emit("rerender-breadcrumb");
    },
    toggleChanged(toggleCategory) {
      if (toggleCategory == "ffe") {
        if (this.quote.ffe_toggle) {
          this.showToast("primary", "Added FF&E budget to spaces.", "Added FF&E budget.");
        } else {
          this.showToast(
            "primary",
            "Removed FF&E budget from spaces.",
            "Removed FF&E budget."
          );
        }
      } else if (this.quote.avit_toggle) {
        this.showToast("primary", "Added AV/IT budget to spaces.", "Added AV/IT budget.");
      } else {
        this.showToast(
          "primary",
          "Removed AV/IT budget from spaces.",
          "Removed AV/IT budget."
        );
      }
      this.hasIncludedInTurnKey();
    },
    updateQuote() {
      this.buttonDiscard = true;

      if (this.quote.issue_date == "" || this.quote.issue_date == null) {
        this.showToast("danger", "Enter a valid issue date", `Invalid issue date`);
        return false;
      }

      this.$http
          .post(`/quote/${this.quoteId}/options/bulk`, {
            additions: this.additions,
            deleted_additions: this.deleted_additions,
          })
          .then(response => {
            const data = response.data.options
            this.options = data

            this.$http
                .put(`/quote/${this.quoteId}`, {
                  construction_buffer: this.quote.construction_buffer * 1,
                  avit_buffer: this.quote.avit_buffer,
                  ffe_buffer: this.quote.ffe_buffer,
                  design_ame_buffer: this.quote.design_ame_buffer,
                  construction_pm_buffer: this.quote.construction_pm_buffer,
                  issue_date: this.quote.issue_date,
                  avit_toggle: this.quote.avit_toggle,
                  av_toggle: this.quote.av_toggle,
                  it_toggle: this.quote.it_toggle,
                  ffe_toggle: this.quote.ffe_toggle,
                  assumptions_exclusions: this.quote.assumptions_exclusions,
                  language: this.quote.language,
                  options: this.options,
                  allowances: this.allowances,
                  engineering_fee_price: this.quote.engineering_fee_price,
                  permit_fees: this.quote.permit_fees,
                  tender_breakdown_hidden: this.quote.tender_breakdown_hidden,
                })
                .then((response) => {
                  this.showToast(
                      "success",
                      `Quote #${response.data.tfr_name} has been successfully saved.`,
                      "Quote saved successfully"
                  );
                  this.$router.push({
                    path: `/opportunities/${this.$route.params.opportunityid}/${this.quoteId}`,
                  });
                })
                .catch((error) => {
                  this.showToast("danger", "Error updating Quote", "Notification");
                });
          })
          .catch(error => {
            this.showToast('danger', 'Error updating Quote', 'Notification')
            this.savingQuote = false
          })

    },
    printInvoice() {
      window.print();
    },
    childItems(variable) {
      this.options = variable.options;
      this.quoteItems = this.options.filter((x) => x.budget_group != "Soft Cost");
      this.scItems = this.options.filter((x) => x.budget_group == "Soft Cost");
    },
    childOptions(variable) {
      let idx = 0
      // * add addition o variable
      if (variable.id != null) {
        let findTempId = false
        let addIdx = 0
        this.options.forEach((value, index) => {
          if (value.id == variable.id) {
            idx = index
            findTempId = true
          }
        })
        // fix for not saving bulk items
        this.additions = this.additions.filter(a => a.id != variable.id)
        this.additions.push(variable)
        this.additions.forEach((value, index) => {
          if (
              value.temp_id == variable.temp_id
              && variable.temp_id != undefined
          ) {
            addIdx = index
            findTempId = true
          }
        })
        if (findTempId) {
          this.options[idx] = variable
          this.additions[addIdx] = variable
        } else {
          this.options.push(variable)
        }
      } else if (variable.temp_id != 0) {
        let findTempId = false
        let addIdx = 0
        this.options.forEach((value, index) => {
          if (
              value.temp_id == variable.temp_id
              && variable.temp_id != undefined
          ) {
            idx = index
            findTempId = true
          }
        })
        this.additions.forEach((value, index) => {
          if (
              value.temp_id == variable.temp_id
              && variable.temp_id != undefined
          ) {
            addIdx = index
            findTempId = true
          }
        })

        if (findTempId) {
          this.options[idx] = variable
          this.additions[addIdx] = variable
        } else {
          this.additions.push(variable)
          this.options.push(variable)
        }
      } else {
        // search and update by id
        if (variable.id != null) {
          this.options.forEach((value, index) => {
            if (value.id == variable.id) {
              idx = index
            }
          })
          this.options[idx] = variable
          this.additions = this.additions.filter(a => a.id != variable.id)
          this.additions.push(variable)
        } else {
          this.options.forEach((value, index) => {
            if (
                value.temp_id == variable.temp_id
                && value.temp_id != undefined
            ) {
              idx = index
            }
          })
          this.options[idx] = variable
          this.additions = this.additions.filter(
              a => a.temp_id != variable.temp_id,
          )
          this.additions.push(variable)
        }
      }
      //calc total prices and cogs
      this.totalPrice = 0;
      this.totalCost = 0;
      this.options.forEach((e) => {
        this.totalPrice += parseFloat(e.total_price);
      });
      this.options.forEach((e) => {
        this.totalCost += parseFloat(e.total_cost);
      });
      this.quoteItems = this.options.filter((x) => x.budget_group != "Soft Cost");
      this.scItems = this.options.filter((x) => x.budget_group == "Soft Cost");
    },
    deleteOption(item) {
      if (item.id == null) {
        this.additions = this.additions.filter(o => o.temp_id != item.temp_id)
        this.options = this.options.filter(o => o.temp_id != item.temp_id)
        this.groups.forEach((g)=>{
          g.items = g.items.filter(o => o.temp_id != item.temp_id)
        })
      } else {
        this.deleted_additions.push(item.id)
        this.groups.forEach((g)=>{
          g.items = g.items.filter(o => o.id != item.id)
        })
        this.options = this.options.filter(o => o.id != item.id)
      }
      //calc total prices and cogs
      this.totalPrice = 0;
      this.totalCost = 0;
      this.options.forEach((e) => {
        this.totalPrice += parseFloat(e.total_price);
      });
      this.options.forEach((e) => {
        this.totalCost += parseFloat(e.total_cost);
      });
      this.quoteItems = this.options.filter((x) => x.budget_group != "Soft Cost");
      this.scItems = this.options.filter((x) => x.budget_group == "Soft Cost");
      this.showToast(
          "success",
          "Click save to apply changes",
          "Removed Successfully"
      );
    },
    initials(text) {
      const initial = text
        .match(/(\b\S)?/g)
        .join("")
        .match(/(^\S|\S$)?/g)
        .join("")
        .toUpperCase();

      return initial;
    },
    editOption(option) {
      this.optionToEdit = option;
      // this.$bvModal.show('edit-options-modal')
    },
    gotoAdditionSet() {
      let route = this.$router.resolve("/catalogue-addition/" + this.addition_set_selected + "/edit");
      window.open(route.href);
    },
    closeAdditionSetModal(){
      this.additionSetModal=false
      this.addition_set_selected=null
    },
    addAdditionSet(){
      // console.log('set addition action')
      this.is_adding_addition=true
      this.$http
        .get(`/additions/sets/${this.addition_set_selected}`,{
            quote_id: this.quote.id,
            addition_set_id: this.addition_set_selected,
          sqft: this.quote.sqft
          })
        .then(response => {
            const { data } = response
            if (data.addition_items.length > 0) {
              data.addition_items.forEach((a,index) => {
                let new_item = {
                  details: a.details,
                  total_price: a.total_price,
                  total_cost: a.total_cost,
                  cost_per_sqft: a.total_cost / this.quote.sqft,
                  price_per_sqft: a.total_price / this.quote.sqft,
                  budget_group: a.budget_group,
                  is_base_spec: a.is_base_spec,
                  tender_code: null,
                  uniformat: a.uniformat,
                  quantity: a.quantity,
                  unit_cost: a.unit_cost,
                  unit_price: a.unit_price,
                  is_adjustment: false,
                  internal_note: 'Addition created from Addition Set',
                  construction_work_id: a.construction_work_id,
                  name: a.name,
                  created: true,
                  temp_id: Date.now()+index,
                  quote_id: this.quote.id,
                  id: null,
                };
                // console.log('new item sent:', new_item);
                this.childOptions(new_item);
              });
            }
            this.closeAdditionSetModal()
            this.showToast("success", "Review and click save to apply changes.", "Addition set applied");
            this.is_adding_addition = false
          })
          .catch(error => {
            this.show = false
            console.log(error)
            this.is_adding_addition=false
            this.showToast('danger', error.response.data.message)
        })

    },
    // deleteOption(id) {
    //   this.$http
    //     .delete(`/quote/${this.quoteId}/options/${id}`)
    //     .then((response) => {
    //       this.showToast(
    //         "success",
    //         " Addition successfully deleted.",
    //         "Deleted Successfully"
    //       );
    //       this.childItems(response.data);
    //     })
    //     .catch((error) => {
    //       this.showToast("danger", "Error Deleting Option", "Notification");
    //     });
    // },

    discardChanges() {
      console.log(this.$refs.commentComponent.newComment);
    },
    showModal() {
      this.buttonDiscard = true;
      this.$bvModal
        .msgBoxConfirm(this.discardModalMessage, {
          title: "You Have Unsaved Changes",
          size: "sm",
          okVariant: "outline-danger",
          okTitle: "Discard Changes",
          cancelTitle: "Return to Edit",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.$router.push({
              path: `/opportunities/${this.$route.params.opportunityid}/${this.quoteId}`,
            });
          } else {
            this.buttonDiscard = false;
            return false;
          }
        });
    },
    exitEditMode() {
      this.$router.push({
        path: `/opportunities/${this.$route.params.opportunityid}/${this.quoteId}`,
      });
    },
    visibleActions() {
      return this.quote.tfr.tfr_status == "Quote Completed" ||
        this.quote.opportunity.ccdc_signed == true ||
        this.quote.tfr.tfr_status == "Archived"
        ? "d-none"
        : "";
    },

    badgeClass(status) {
      if (status == "Awaiting Test Fit") {
        return "awaiting-test-fit";
      } else if (status == "Reviewing Test Fit") {
        return "reviewing-test-fit";
      } else if (status == "Archived") {
        return "archived";
      } else if (status == "Awaiting Quote") {
        return "awaiting-quote";
      } else if (status == "Quote in Progress") {
        return "quote-in-progress";
      } else if (status == "Submitted for Handover" || status == "Active") {
        return "submitted-for-handover";
      } else if (status == "Reviewing Quote") {
        return "reviewing-quote";
      } else if (status == "Sent to Client") {
        return "sent-to-client";
      } else if (status == "Hardened") {
        return "hardened";
      } else if (status == "Quote Completed") {
        return "quote-completed";
      }
    },
    onChange(event) {
      this.reorder(event);
    },
    reorder(indexG) {
      // Assign an order to each item in the specified group
      this.groups[indexG].items.forEach((item, index) => {
        item.order = index + 1; // Assign sequential order
      });
      this.groups[indexG].items.forEach((item)=>{
        this.childOptions(item)
      })
      console.log(this.groups[indexG].items)
      this.options= [...this.groups[indexG].items]
    },

    commentChanged(comment) {
      this.childComment = comment;
    },
    searchUniformatCodeName(subgroup, code) {
      subgroup = subgroup == "Soft Cost" ? "Soft Costs" : subgroup;
      let codeName = "";
      this.uniformat_codes[subgroup].map((u) => {
        if (u.code == code) {
          codeName = u.code_name;
        }
      });
      return codeName;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>
<style lang="scss">
@media print {
  // Global Styles
  body {
    background-color: transparent !important;
  }

  .breadcrumbs-top {
    display: none;
  }

  nav.header-navbar {
    display: none;
  }

  .main-menu {
    display: none;
  }

  .header-navbar-shadow {
    display: none !important;
  }

  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }

  footer.footer {
    display: none;
  }

  .card {
    background-color: transparent;
    box-shadow: none;
  }

  .customizer-toggle {
    display: none !important;
  }

  [dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
  [dir] .table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
    background-color: rgba(92, 177, 231, 0.12) !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
  th {
    text-align: left !important;
  }

  #lottie {
    display: none;
  }

  .caption-table {
    /* Heading/H4 */

    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 18px;
    padding-left: 40px;
    color: #5e5873;
  }

  .row-label {
    /* Body/Paragraph Semi Bold */

    font-style: normal;
    font-weight: 600;
    font-size: 14px;

    /* identical to box height, or 150% */

    align-items: center;

    /* Typography/Body */

    color: #6e6b7b;
  }

  .border-group {
    border-bottom: 2px solid #d8d6de;
  }

  .border-child {
    border-bottom: 2px solid white;
  }

  .child-label {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
  }

  .border-footer {
    border-top: 2px solid  rgba(59, 171, 255, 1) ;
  }

  .invoice-numbercs {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 18px;
  }

  .header-label {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
  }

  .header-label-grey {
    /* Table/Table Header */

    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    /* identical to box height */

    text-align: right;
    letter-spacing: 1px;
    text-transform: uppercase;

    /* Typography/Muted & Placeholder */

    color: #b9b9c3;
  }

  .header-subtotal {
    font-style: normal;
    font-weight: 600;
    font-size: 11.9px;
    line-height: 12px;
    /* identical to box height, or 100% */

    text-align: right;
    letter-spacing: 0.14px;

    /* Typography/Body */

    color: #6e6b7b;
  }

  .project-sqft {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height, or 150% */

    /* Typography/Heading & Display */

    color: #5e5873;
  }

  .project-sqft-total {
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 34px;
    text-align: end;

    /* Theme Colour / Primary */

    color:  rgba(59, 171, 255, 1) ;
  }

  .width-100p {
    width: 100%;
  }
}

th {
  text-align: left !important;
}

.comment-label {
  font-weight: 600;
  font-size: 12px;
  line-height: 23px;
  letter-spacing: 0.6px;
  text-transform: uppercase;
}

.caption-table {
  /* Heading/H4 */

  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
  padding-left: 40px;
  color: #5e5873;
}

.row-label {
  /* Body/Paragraph Semi Bold */

  font-style: normal;
  font-weight: 600;
  font-size: 14px;

  /* identical to box height, or 150% */

  align-items: center;

  /* Typography/Body */

  color: #6e6b7b;
}

.border-group {
  border-bottom: 2px solid #d8d6de;
}

.border-child {
  border-bottom: 2px solid white;
}

.child-label {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
}

.border-footer {
  border-top: 2px solid  rgba(59, 171, 255, 1) ;
}

.invoice-numbercs {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
}

.header-label {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
}

.header-label-grey {
  /* Table/Table Header */

  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  /* identical to box height */

  text-align: right;
  letter-spacing: 1px;
  text-transform: uppercase;

  /* Typography/Muted & Placeholder */

  color: #b9b9c3;
}

.header-subtotal {
  font-style: normal;
  font-weight: 600;
  font-size: 11.9px;
  line-height: 12px;
  /* identical to box height, or 100% */

  text-align: right;
  letter-spacing: 0.14px;

  /* Typography/Body */

  color: #6e6b7b;
}

.project-sqft {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height, or 150% */

  /* Typography/Heading & Display */

  color: #5e5873;
}

.project-sqft-total {
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  text-align: end;

  /* Theme Colour / Primary */

  color:  rgba(59, 171, 255, 1) ;
}

.width-100p {
  width: 100%;
}

.child-line {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #b9b9c3;
}

.cost-line {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #b9b9c3;
}

.cost-header {
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  color: #b9b9c3;
}

.child-description {
  font-size: 12px;
  color: #b9b9c3;
  line-height: 18px;
}

.turnkey-subtotal-primary {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
  color: #6e6b7b;
}

.turnkey-subtotal-secondary {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
  color: #b9b9c3;
}

.credit {
  color:  rgba(59, 171, 255, 1) ;
}

.disclaimer {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #b9b9c3;
}

.labelpersqft {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;

  color: #b9b9c3;
}

th {
  text-align: center;
}

.alert-warning {
  width: 100%;
  background: linear-gradient(0deg, rgba(255, 159, 67, 0.12), rgba(255, 159, 67, 0.12)),
    #ffffff;
  border-radius: 4px;
  margin-bottom: 2rem;
  height: 39px;
  display: flex;
  align-items: center;
}

.alert-warning > span {
  color: #ff9f43;
  font-weight: 700;
  font-size: 14px;
  padding-left: 2%;
}

.archived-warning {
  width: 100%;
  background: #d4f4f7;
  border-radius: 4px;
  margin-bottom: 1rem;
  height: 39px;
  display: flex;
  align-items: center;
}

.archived-warning > span {
  color: #03d8ed;
  font-weight: 700;
  font-size: 14px;
  padding-left: 2%;
}

.hidden-flex-line > td {
  font-size: 12px;
}

.remove-border > td {
  border: none !important;
}
.border-section > td {
  border-bottom: 1px solid #ebe9f1;
}

.input-group > .reset-append-border > .input-group-text {
  border-left: 1px solid #d8d6de !important;
  border-top-left-radius: 0.357rem !important;
  border-bottom-left-radius: 0.357rem !important;
}
.input-group:focus-within > .reset-append-border > .input-group-text {
  border-left: 1px solid  rgba(59, 171, 255, 1)  !important;
  border-top-left-radius: 0.357rem !important;
  border-bottom-left-radius: 0.357rem !important;
}

// @media screen and (min-width: 360px) and (max-width: 1300px) {
//     .edit-page [role=tablist] {
//       width: min-content;
//     }
// }
// @media screen and (max-width: 1440px){
//     .edit-page [role=tablist] {
//       width: 260px;
//     }
// }
// @media screen and (min-width: 1441px){
//     .edit-page [role=tablist] {
//       width: 350px;
//     }
// }

.FFE {
  background-color: rgba(32, 201, 151, 0.12);
  color: #20c997;
}

.Construction {
  background-color: rgba(255, 159, 67, 0.12);
  color: #ff9f43;
}

.AVIT {
  background-color: rgba(13, 110, 253, 0.12);
  color: #7367f0;
}

.b-table-sticky-header {
  max-height: unset;
}
.center_header {
  text-align: center !important;
}
.is-hidden {
  display: none;
}
</style>
